
require('./fancybox.umd');



$('[data-toggle="offcanvas"]').on('click', function () {
    //console.log('offcanvas click');
    $('.offcanvas-collapse').toggleClass('open');
  });


$(document).ready(function(){

$("#myDataTable").DataTable();

});
